.RowFilename {
    user-select: none;
    -webkit-user-select: none;
}

td.RowFilename {
    padding: 2px 5px;
}

.selected {
    border: 1px solid black;
}

.fileRow {
    text-align: left;
}

.fileBrowser:after {
    content: "";
    display: table;
    clear: both;
  }

.fileBrowser>div {
    float: left;
    width: 50%;
}