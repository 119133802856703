.WindowBounds {
  text-align: center;
  height: calc(100vh - 41px - 40px);
  margin: 20px;

  box-sizing: border-box;
}

.App {
  
}

body { height: 100% } 
