.windowHeader {
    border-radius: 10px 10px 0 0;
    border-bottom: 2px solid black;

    /* Prevent touch */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.windowBody {
    background-color: white;
    height: calc(100% - 48px);
    overflow-x: auto;
    overflow-y: scroll;
}

.window {
    background-color: white;
    border: 2px solid black;
    border-radius: 10px;
}

.windowTitle {
    margin: 0;
    padding: 10px;
    font-weight: bold;
}

.windowControls {
    position: absolute;
    right: 10px;
    top: 10px;
}

.WindowBar {
    position: absolute;
    bottom: 0;
    background-color: lightgray;
    width: 100%;
}

.WindowBar button {
    border: 1px solid black;
    border-radius: 0;
    padding: 10px 20px;
    font-size: 15px;
    background-color: white;
}

.WindowBar button.minimised {
    background-color: lightgray;
}